import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { LazyLoadImage } from "react-lazy-load-image-component"

/*
 * This component is built using `gatsby-image` to automatically serve optimized
 * images with lazy loading and reduced file sizes. The image is loaded using a
 * `useStaticQuery`, which allows us to load the image from directly within this
 * component, rather than having to pass the image data down from pages.
 *
 * For more information, see the docs:
 * - `gatsby-image`: https://gatsby.dev/gatsby-image
 * - `useStaticQuery`: https://www.gatsbyjs.org/docs/use-static-query/
 */

const Car = () => {
  // const data = useStaticQuery(graphql`
  //   query {
  //     placeholderImage: file(relativePath: { eq: "car-wrap.jpg" }) {
  //       childImageSharp {
  //         fluid(maxWidth: 2000) {
  //           ...GatsbyImageSharpFluid
  //         }
  //       }
  //     }
  //   }
  // `)

  // return <Img fluid={data.placeholderImage.childImageSharp.fluid} />
  const data = useStaticQuery(graphql`
    {
      allFile(filter: { relativePath: { eq: "car-wrap.jpg" } }) {
        edges {
          node {
            id
            childImageSharp {
              fluid(maxWidth: 2000) {
                originalName
                src
              }
            }
          }
        }
      }
    }
  `)

  return (
    <LazyLoadImage
      src={data.allFile.edges[0].node.childImageSharp.fluid.src}
      effect="blur"
    />
  )
}

export default Car
