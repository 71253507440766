import React from "react"

import Layout from "../components/theme/layout"
import SEO from "../components/theme/seo"
import Onecol from "../components/onecol"
import TwocolWithStaticImage from "../components/twocol-with-static-image"
import ImageCTA from "../components/imageCTA"
import ButtonCTA from "../components/buttonCTA"
import { graphql } from "gatsby"

import Banner from "../components/banner"

import VehicleBanner from "../images/vehicle-wraps-banner.jpg"
import Car from "../components/images/wraps-decals/car-wrap"

import { Palette } from "tabler-icons-react"
import { Sticker } from "tabler-icons-react"

export default function Vehicle({ data }) {
  const clothesImage = data.allFile.edges[0].node.childImageSharp.fluid.src

  return (
    <Layout>
      <SEO
        title="Vehicle Wraps"
        description="Need indoor, outdoor & mobile advertising? From store front decals & stickers to customized automotive & marine wraps, we’ll make sure you get noticed."
      />

      <Banner
        title="Wraps &amp; Decals"
        description="We wrap your vehicles the right way!"
        img={VehicleBanner}
      />

      {data.allStrapiVehicleWraps.edges.map(({ node }) => (
        <>
          <Onecol
            key={node.vehicle_intro.id}
            title={node.vehicle_intro.one_col_title}
            description={node.vehicle_intro.one_col_description}
          />

          <TwocolWithStaticImage
            key={node.vehicle_two_col.id}
            textLeft={true}
            intro={node.vehicle_two_col.two_col_intro}
            iconOne={<Palette size={40} strokeWidth={2} color={"#DD2E39"} />}
            titleOne={node.vehicle_two_col.title_one}
            linkOne="/wraps-decals/wraps"
            descriptionOne={node.vehicle_two_col.description_one}
            iconTwo={<Sticker size={40} strokeWidth={2} color={"#DD2E39"} />}
            titleTwo={node.vehicle_two_col.title_two}
            linkTwo="/wraps-decals/decals-signs"
            descriptionTwo={node.vehicle_two_col.description_two}
          >
            <Car />
          </TwocolWithStaticImage>

          <ImageCTA
            title="Affordable, branded items to boost your visibility."
            image={clothesImage}
            link="/contact-us"
            linkText="inquire today"
          />
        </>
      ))}
    </Layout>
  )
}

// export default Home

export const query = graphql`
  query vehiclewraps {
    allFile(filter: { relativePath: { eq: "promo-items.jpg" } }) {
      edges {
        node {
          id
          childImageSharp {
            fluid(maxWidth: 2000) {
              originalName
              src
            }
          }
        }
      }
    }
    allStrapiVehicleWraps {
      edges {
        node {
          vehicle_hero {
            id
            hero_title
            hero_description
          }
          vehicle_intro {
            id
            one_col_title
            one_col_description
          }
          vehicle_two_col {
            id
            two_col_intro
            title_one
            description_one
            title_two
            description_two
          }
        }
      }
    }
  }
`
