import React from "react"
import { Link } from "gatsby"
import { Container, Grid } from "@material-ui/core"
import { LazyLoadImage } from "react-lazy-load-image-component"

export default function TwoCol({
  grey,
  textLeft,
  intro,
  linkOne,
  iconOne,
  titleOne,
  descriptionOne,
  linkTwo,
  iconTwo,
  titleTwo,
  descriptionTwo,
  children,
}) {
  return (
    <div className={`${grey === true ? "grey-bg py-40" : "white-bg"} mb-40`}>
      <Container>
        <Grid
          items
          container
          className={`twocol-list flex justify-between items-center ${
            textLeft === true ? "md:flex-row-reverse" : null
          }`}
        >
          <Grid md={6} sm={12}>
            {children}
          </Grid>
          <Grid md={5} sm={12}>
            <h4>{intro}</h4>
            <div className="twocol-item">
              <Link className="twocol-block" to={linkOne}>
                <div className="icon">{iconOne}</div>
                <div className="text">
                  <h6>{titleOne}</h6>
                  <p>{descriptionOne}</p>
                </div>
              </Link>
              <Link className="twocol-block" to={linkTwo}>
                <div className="icon">{iconTwo}</div>
                <div className="text">
                  <h6>{titleTwo}</h6>
                  <p>{descriptionTwo}</p>
                </div>
              </Link>
            </div>
          </Grid>
        </Grid>
      </Container>
    </div>
  )
}
